// Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
// for details on configuring this project to bundle and minify static web assets.
// Write your JavaScript code.
import * as jquery from "jquery";
import "popper.js";
import "jquery-validation";
import "jquery-validation-unobtrusive";
import "jquery.easing";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.css";
import moment from "../../node_modules/moment/moment";
import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/css/all.css";
import "startbootstrap-sb-admin-2/js/sb-admin-2.js";
import "startbootstrap-sb-admin-2/css/sb-admin-2.css";
import "startbootstrap-sb-admin-2/vendor/bootstrap/js/bootstrap.bundle.js";
import "sweetalert2";
import "jquery-mask-plugin/dist/jquery.mask.js";
import "jquery-ui/dist/jquery-ui.js";
import "jquery-ui/dist/jquery-ui.js";
import "@selectize/selectize/dist/js/selectize.js";
import "@selectize/selectize/dist/css/selectize.bootstrap4.css";
import "select2/dist/css/select2.css";
import "select2/dist/js/select2.full.js";
import "bootstrap-select/dist/css/bootstrap-select.css";
import "bootstrap-select/dist/js/bootstrap-select.js";
import "bootstrap4-toggle/js/bootstrap4-toggle.min";
import "bootstrap4-toggle/css/bootstrap4-toggle.min.css";
import "@wenlogic/shelf/dist/shelf";
import "@wenlogic/shelf/dist/bootstrap4/bootstrap4.js";
import "@wenlogic/shelf/dist/bootstrap4/bootstrap4.css";
import "../css/site.css";
import "../css/site.ie.css";
import "../js/submit_enter.js";
import "../../node_modules/c3/c3.css";
import * as c3 from "../../node_modules/c3/c3";
var agGrid = require("../../node_modules/ag-grid-enterprise/dist/ag-grid-enterprise");
agGrid.LicenseManager.setLicenseKey("CompanyName=Physicians Lab,LicensedApplication=Physicians Lab,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-018186,ExpiryDate=20_August_2022_[v2]_MTY2MDk1MDAwMDAwMA==66319687db264cdeebaf3b7ebb29cfea");
window.$ = jquery;
window.jQuery = jquery;
window.moment = moment;
window.c3 = c3;
window.agGrid = agGrid;
