$(document).ready(function () {

    function keypressHandler(e) {
        if (e.which == 13) {
            e.preventDefault();
            $(this).blur();
            $('submit').focus().click();
        }
    }
    $('submit').keypress(keypressHandler);
});